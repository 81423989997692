<!-- @format -->
<script lang="ts">
  import type { ManageAPI } from "~/manage-api/types";
  import { getStoresContext, type StoresKey } from "~/stores";
  import { validity } from "./utils";
  export let request_store_key: StoresKey;
  import { getContext } from "svelte";
  import type { CampaignConfig } from "./CampaignItemListing.svelte";
  import { toRangeOfDaysOfTheWeek } from "~/util/date-n-time";
  import OpeningHoursRange from "~/components/opening-hours/OpeningHoursRange.svelte";

  export let offer: ManageAPI.Offer;

  const config: CampaignConfig = getContext("config");
  const { locale } = config;

  const { stores, site, T } = getStoresContext(request_store_key);
  $: alt = offer?.store?.name || $site.name;
  $: store = $stores.find((store) => store.id === offer?.store?.id);
  $: floor = store?.floor?.description
</script>

{#if offer}
  <div class="row">
    <div class="col-12"><h3>{alt}</h3></div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-5">
      <img
        src={offer.highResolutionImage}
        alt={offer.title}
        width="100%"
        height="auto"
        class="mb-4 mb-lg-2" />
      <div class="d-lg-block d-none">
        {#if floor}
          <p>{floor}</p>
        {/if}
        {#if store}
          {#each store.unformattedOpeningHours as item}
            <p class="mb-0">
              {@html toRangeOfDaysOfTheWeek(item.days, $locale)}:
              <OpeningHoursRange
                locale={$locale}
                {item}
                opening_hours={store.unformattedOpeningHours}
                t={$T} />
            </p>
          {/each}
        {/if}
      </div>
    </div>
    <div class="col-xl-8 col-lg-7 description">
      <p class="mb-1">
        <i
          >{$T`offers.valid`}: {validity(
            offer,
            'fi-FI',
            config.date_format,
          )}</i>
      </p>
      <h2>{offer.title}</h2>
      <p class="description1-2">{offer.description1} {offer.description2}</p>
      {#if offer.lowestPriceInXDays}
        <p class="omnibus-statement">
          <i>{$T`offers.daysLowestPrice`}: {offer.lowestPriceInXDays}</i>
        </p>
      {/if}
      <p>{offer.longDescription}</p>

      <div class="d-lg-none">
        {#if floor}
          <p>{floor}</p>
        {/if}
        {#if store}
          {#each store.unformattedOpeningHours as item}
            <p class="mb-0">
              {@html toRangeOfDaysOfTheWeek(item.days, $locale)}:
              <OpeningHoursRange
                locale={$locale}
                {item}
                opening_hours={store.unformattedOpeningHours}
                t={$T} />
            </p>
          {/each}
        {/if}
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .description1-2{
    font-weight: 500;
  }
  h2 {
    @media screen and (min-width: 992px) {
      font-size: 31px;
    }
  }
  h3 {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 20px;
    max-width: 80%;
    @media screen and (min-width: 992px) {
      font-size: 30px;
    }
  }
  .description {
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }
</style>
